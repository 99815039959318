<template>
  <v-app style="width: 100%;">
    <v-card>
      <v-card-title>
        Students In Class
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="students"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:item.id="{item}">
          <v-chip small :color="`#9c9c9c`" style="color: white; font-weight: 600; width: 25px; padding: 8px;">
            {{students.map(function(x) {return x.id; }).indexOf(item.id)+1}}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>

<script>
export default {
  name: 'student-in-class',
  data () {
    return {
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Names', value: 'names' },
        { text: 'Email', value: 'email' },
        { text: 'Date of birth', value: 'dob' },
        { text: 'Birth place', value: 'birth_place' },
        { text: 'Nationality', value: 'nationality' },
        { text: 'Sex', value: 'sex' },
        { text: 'Reg number', value: 'reg_no' },
        { text: '', value: 'actions' },
      ],
      students: [],
    }
  },
  created () {
    this.fetchStudents()
  },
  methods: {
    fetchStudents () {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      this.axios({
        // url: 'get_students/' + start + '/' + end,
        url: 'get_students_class/' + this.$route.params.class_id,
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + this.header,
        },
      })
        .then((response) => {
          // Then injecting the result to datatable parameters.
          this.loading = false
          this.students = response.data.students
          this.totalDesserts = parseInt(response.data.total)
          return response.data
        })
        .catch(() => {
          this.loading = false
          this.students = []
        })
    },
  },
}
</script>

<style scoped>

</style>
